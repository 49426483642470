import React, { useCallback, useMemo, useRef, useState } from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import SeeAlso from "../../components/SeeAlso/SeeAlso"
import NavBar from "../../components/NavBar/NavBar"

import ScrollWindowList from "./components/scrollReactWindow"
import { isSSR } from "../../components/Script/script"
import Popup from "../../componentForPages/Popup/Popup"

import {
  Title,
  Input,
  Button,
  Dropdown,
  Icon,
  Field,
  Control,
  ArrowDown,
  Columns,
  Column,
} from "../../StyleComponents/styles"
import { Table } from "../../StyleComponents/pagesStyle"

const DropDownList = React.lazy(() => import("./components/DropDown"))
const CodeEditor = React.lazy(() => import("./components/codeEditor"))
const GoogleAds = React.lazy(() => import("../../components/ADS/ADS"))

const seeAlsoArray = [
  "/emoticons-copy-and-paste",
  "/font-generator",
  "/text-emoticons-face-generator",
  "/emoji-copy-and-paste",
  "/text-art",
]

function EmojiNamesList(props) {
  const { singlePageInfo, everyEntityType, allPages } = props.pageContext
  let [emoji, setEmoji] = useState("")
  let [show, setShow] = useState(false)
  let smallInput = useRef("")

  const mycopy = useCallback(e => {
    let emoji = e.target.value
    e.target.select()
    let copied = document.execCommand("copy")
    if (copied) {
      setEmoji(emoji)
      setShow(true)
      setTimeout(function () {
        setShow(false)
      }, 1500)
    }
  }, [])

  const buttonCopy = e => {
    let button = e.target
    smallInput.current.select()
    let copied = document.execCommand("copy")
    if (copied) {
      button.textContent = "Copied 👍"
      setTimeout(function () {
        button.textContent = "Copy"
      }, 1500)
    }
  }
  let decodeHTML = function (html) {
    if (!isSSR) {
      let txt = document.createElement("textarea")
      txt.innerHTML = html
      return txt.value
    }
  }
  return (
    <Layout location={props.location}>
      <SEO
        title={`${singlePageInfo.Character} ${singlePageInfo.Name} Character `}
        description={`"${singlePageInfo.Character}" ${singlePageInfo.Name} Entity,${singlePageInfo.Name} Decimal,${singlePageInfo.Name} Hexadecimal, ${singlePageInfo.Name} Copy And Paste Characters.`}
        keywords={[
          `${singlePageInfo.Name},${singlePageInfo.Name} Decimal, ${singlePageInfo.Name} Entity, ${singlePageInfo.Name} Hexadecimal,${singlePageInfo.Name} Characters`,
        ]}
      />
      <NavBar
        listPages={useMemo(() => {
          return [
            { name: "Utility Tools", link: "/utility-tools/" },
            { name: "Html Entity", link: "/html-entity/" },
            {
              name: singlePageInfo.Name,
              link: `/html-entity/${singlePageInfo.Name.split(" ")
                .join("-")
                .toLowerCase()}-character-entity/`,
            },
          ]
        }, [singlePageInfo.Name])}
      />
      <section className="container">
        <Title id="listOfEmoji">{singlePageInfo.Name}</Title>
        <br />
        <Columns>
          <Column>
            <Input
              fontSize="4rem"
              fontSizeMobile="3rem"
              border="0"
              style={{ textAlign: "center" }}
              aria-label="emoji"
              type="text"
              role="img"
              value={singlePageInfo.Character}
              onFocus={mycopy}
              readOnly
            />
            {singlePageInfo.Entity ? (
              <p>Entity: {singlePageInfo.Entity}</p>
            ) : (
              ""
            )}
            <br />
            <Field>
              <Control>
                <Input
                  type="text"
                  maxWidth="100px"
                  className="borderedLeft"
                  aria-label="emoji-Small"
                  role="img"
                  value={singlePageInfo.Character}
                  ref={smallInput}
                  readOnly
                />
              </Control>
              <Control>
                <Button
                  margin="0"
                  className="staticButton mb-2"
                  borderColor="#1da1f2"
                  color="#1da1f2"
                  hoverColor="white"
                  hoverBorderColor="#1da1f2"
                  hoverBackgroundColor="#1da1f2"
                  onClick={buttonCopy}
                >
                  Copy
                </Button>
              </Control>
            </Field>
            <br />
            <Table>
              <tbody>
                <tr>
                  <th>Name:</th>
                  <td className="has-text-link">{singlePageInfo.Name}</td>
                </tr>
                <tr>
                  <th>Character</th>
                  <td>{singlePageInfo.Character}</td>
                </tr>
                <tr>
                  <th>Entity</th>
                  <td>{singlePageInfo.Entity}</td>
                </tr>
                <tr>
                  <th>Decimal</th>
                  <td>{singlePageInfo.Decimal}</td>
                </tr>
                <tr>
                  <th>Hexadecimal</th>
                  <td>{singlePageInfo.Hexadecimal}</td>
                </tr>
              </tbody>
            </Table>
            <br />
            <h3>HTML Code for {singlePageInfo.Name}</h3>
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <CodeEditor
                  code={`${
                    singlePageInfo.Decimal ? singlePageInfo.Decimal : ""
                  }${
                    singlePageInfo.Hexadecimal
                      ? ` ${singlePageInfo.Hexadecimal}`
                      : ""
                  }${singlePageInfo.Entity ? ` ${singlePageInfo.Entity}` : ""}`}
                  title={singlePageInfo.Name}
                  clas="html-entity"
                />
              </React.Suspense>
            )}
            <p className="h4">Preview: </p>
            <h5>{singlePageInfo.Name}</h5>
            <p>
              {`${
                singlePageInfo.Decimal ? decodeHTML(singlePageInfo.Decimal) : ""
              } ${
                singlePageInfo.Hexadecimal
                  ? decodeHTML(singlePageInfo.Hexadecimal)
                  : ""
              } ${
                singlePageInfo.Entity ? decodeHTML(singlePageInfo.Entity) : ""
              }`}
            </p>
          </Column>
          <Column>
            {/* google ads UnitPediaRight */}
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <GoogleAds
                  slot="1189740279"
                  data-ad-format="auto"
                  data-ad-layout-key="-gi+g+7v-1q-6r"
                  data-full-width-responsive="true"
                />
              </React.Suspense>
            )}
          </Column>
        </Columns>
        {show && (
          <Popup
            title={emoji + " have been copied"}
            emoji={emoji}
            close={() => setShow(false)}
          />
        )}
        <br />
        <Dropdown className="mb-4">
          <Button aria-haspopup="true" aria-controls="dropdown-menu">
            Characters Category
            <Icon>
              <ArrowDown />
            </Icon>
          </Button>
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <DropDownList list={allPages} />
            </React.Suspense>
          )}
        </Dropdown>
        <br />
        <ScrollWindowList pageItems={everyEntityType} mycopy={mycopy} />
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </section>
    </Layout>
  )
}

export default EmojiNamesList
